/* eslint-disable import/no-cycle */
// Needed for updating new network

import { TranslatableText } from 'state/types'

export type PageMeta = {
  title: string
  description?: string
  image?: string
}

export type IfoStatus = 'idle' | 'coming_soon' | 'live' | 'finished'

export interface Ifo {
  id: string
  isActive: boolean
  address: string
  name: string
  subTitle?: string
  description?: string
  launchDate: string
  launchTime: string
  saleAmount: string
  raiseAmount: string
  cakeToBurn: string
  projectSiteUrl: string
  currency: string
  currencyAddress: string
  tokenDecimals: number
  tokenSymbol: string
  releaseBlockNumber: number
  campaignId?: string
}

export interface Token {
  symbol: string
  altSymbol?: TokenSymbol
  address: Address
  decimals?: number
  projectLink?: string
  busdPrice?: string
  isLP?: boolean
  // v2
  tokenSymbol?: string
  tokenAddress?: string
  tokenImage?: string
  tokenName?: string
}

export enum QuoteToken {
  'BNB' = 'BNB',
  'CAKE' = 'CAKE',
  'SYRUP' = 'SYRUP',
  'BUSD' = 'BUSD',
  'TWT' = 'TWT',
  'UST' = 'UST',
  'ETH' = 'ETH',
  'COMP' = 'COMP',
  'SUSHI' = 'SUSHI',
  'IF' = 'IF',
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
}

// Add new chains here
export enum Chain {
  'BSC_MAINNET' = 56,
  'BSC_TESTNET' = 97,
  'POLYGON_MAINNET' = 137,
  'MOONRIVER_MAINNET' = 1285,
  'MOONBEAM_MAINNET' = 1284,
  'AVAX_MAINNET' = 43114,
  'ARBITRUM_MAINNET' = 42161,
  'ARBITRUM_TESTNET' = 421611,
  'OPTIMISM_MAINNET' = 10,
  'OPTIMISM_TESTNET' = 69,
  'GOERLI' = 5,
  'ETH_MAINNET' = 1,
  'SEPOLIA' = 11155111,
  'AURORA_MAINNET' = 1313161554,
  'NEON_DEVNET' = 245022926,
  'OKC_MAINNET' = 66,
  'OMNI_TESTNET' = 165,
  'ZKSYNC_ERA_MAINNET' = 324,
  'ZKSYNC_SEPOLIA' = 300,
  'MANTLE' = 5000,
  'ARBITRUM_GOERLI' = 421613,
  'ARBITRUM_SEPOLIA' = 421614,
  'LINEA_TESTNET' = 59140,
  'HUMANODE_TESTNET' = 15110,
  'RONIN' = 2020,
  'SAIGON' = 2021,
  'HUMANODE_MAINNET' = 5234,
  'MANTA_MAINNET' = 169,
  'OKX_TESTNET' = 195,
  'BASE_SEPOLIA' = 84532,
  'BEAM' = 4337,
  'BEAM_TEST' = 13337,
  'IMMUTABLE' = 13371,
  'IMMUTABLE_TEST' = 13473,
  'POLYGON_AMOY' = 80002,
}

export enum AddressState {
  'VERIFIED' = 'VERIFIED',
  'BLACKLISTED' = 'BLACKLISTED',
  'NOT_FOUND' = 'NOT_FOUND',
  'PENDING' = 'PENDING',
  'CANCELLED' = 'CANCELLED',
  'UNKNOWN' = 'UNKNOWN',
}

export interface SerializedAddressState {
  [address: string]: {
    state: string
    country?: string
  }
}

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
  projectLink?: string
}

export type TokenSymbol = {
  readonly [chainId in Chain]?: string
}
export type Address = {
  readonly [chainId in Chain]?: string
}

export type AverageBlockTime = {
  readonly [chainId in Chain]: number
}
export interface FarmConfig {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  tokenSymbol: string
  tokenAddresses: Address
  quoteTokenSymbol: QuoteToken
  quoteTokenAdresses: Address
  multiplier?: string
  isCommunity?: boolean
  hide?: boolean
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
  isPancake?: boolean
}

export interface PoolConfig {
  sousId: number
  image?: string
  tokenName: string
  stakingTokenName: QuoteToken
  stakingLimit?: number
  stakingTokenAddress?: string
  contractAddress: Address
  poolCategory: PoolCategory
  projectLink: string
  tokenPerBlock: string
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
  tokenDecimals: number
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}

export type NftImages = {
  blur?: string
} & Images

export type NftVideo = {
  webm: string
  mp4: string
}

export type Nft = {
  name: string
  description: string
  images: NftImages
  sortOrder: number
  bunnyId: number
  video?: NftVideo
}

export type TeamImages = {
  alt: string
} & Images

export type Team = {
  id: number
  name: string
  description: string
  isJoinable?: boolean
  users: number
  points: number
  images: TeamImages
  background: string
  textColor: string
}

export type CampaignType = 'ifo'

export type Campaign = {
  id: string
  type: CampaignType
  title?: TranslatableText
  description?: TranslatableText
  badge?: string
}
